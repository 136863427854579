<template>
  <b-overlay :show="isLoading" variant="transparent" opacity="0.99" blur="5px" rounded="sm">
    <template #overlay>
      <div class="text-center">
        <b-icon-controller font-scale="3" animation="cylon" />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card no-body>
      <b-card-title>
        <div class="p-2">
          รายการของรางวัล
        </div>
      </b-card-title>
      <b-table v-if="rewards.length" small striped hover responsive show-empty class="position-relative items-center" :fields="fields"
        :items="rewards">
        <template #empty="scope">
          <div class="p-2 text-center">
            <img
              src="/box.png"
              alt="empty"
              height="40px"
            > {{ scope.emptyFiltered ? 'No matching records found' : 'ไม่พบข้อมูล' }}
          </div>
        </template>
        <template #cell(no)="row">
          {{ row.item.no }}
        </template>
        <template #cell(reward)="row">
          {{ row.item.reward }}
        </template>
        <template #cell(value)="row">
          {{ row.item.value.toLocaleString() }} บาท
        </template>
        <template #cell(chip)="row">
          {{ row.item.chip.toLocaleString() }}
        </template>
        <template #cell(type)="row">
          {{ row.item.type === 1 ? 'ของรางวัล' : 'เงิน' }}
        </template>
        <!-- <template #cell(status)="row">
          <b-badge v-if="row.item.status == 0" pill>รอ</b-badge>
          <b-badge v-if="row.item.status == 1" pill variant="info">ดำเนินการ</b-badge>
          <b-badge v-if="row.item.status == 2" pill variant="success">สำเร็จ</b-badge>
        </template> -->
      </b-table>
      <b-card-body v-if="items" class="d-flex justify-content-between flex-wrap pt-0">
        <b-pagination v-model="items.page" :total-rows="items.total" :per-page="items.perPage" first-number last-number
          prev-class="prev-item" next-class="next-item" class="mb-0" @change="getPredictions">
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-card-body>
    </b-card>

    <div>
      <ExchangeList :items="rewardRequests"/>
      <!-- <LuckyWinner :items="luckyWinners"/> -->
    </div>
  </b-overlay>
</template>

<script>
import {
  BCardTitle, BIcon, BDropdownItem, BDropdown, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody
} from 'bootstrap-vue'
// import CreatePredictionModal from './index/createPredictionModal.vue'
import ExchangeList from './index-exchange-list.vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment-timezone'

export default {
  components: {
    BCardTitle, BIcon, BDropdownItem, BDropdown, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody,
    ExchangeList
  },
  directives: {
    Ripple
  },
  data: () => ({
    isLoading: false,
    fields: [
      { key: 'no', label: 'รางวัลที่' },
      { key: 'reward', label: 'รางวัล' },
      { key: 'value', label: 'มูลค่า' },
      { key: 'chip', label: 'Chip' },
      { key: 'type', label: 'ประเภท' },
      { key: 'actions', label: '#' },
    ],
    rewards: [],
    items: null,
    rewardRequests: null,
    competitions: [],
  }),
  filters: {
    convertDate(date) {
      return moment(date).tz('Asia/Bangkok').format('DD/MM/YYYY')
    },
    convertTime(date) {
      return moment(date).tz('Asia/Bangkok').format('HH:ss')
    },
  },
  mounted() {
    this.getRewards();
    this.getRewardRequests(1);
    // this.getCompetition(new Date);
  },
  methods: {
    async getRewards() {
      try {
        const { data } = await this.$http.get('/events/reward');

        this.rewards = data;
      } catch (error) {
        console.log(error);
      }
    },
    async getRewardRequests(page) {
      try {
        const { data } = await this.$http.get(`/events/reward_requests?page=${page}`);

        this.rewardRequests = data;
      } catch (error) {
        console.log(error);
      }
    },
    async createPrediction(values) {
      this.isLoading = true;

      try {
        const { data } = await this.$http.post('events/lotto-predictions', values);

        console.log(values);

        await this.$swal({
          icon: 'success',
          title: 'ทำรายการสำเร็จ',
          text: data.message,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })

        await this.getPredictions(1);

      } catch (e) {
        console.log(e.response.data.message);
        await this.$swal({
          icon: 'error',
          title: 'ทำรายการไม่สำเร็จ',
          text: e.response.data.message,
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        })


      } finally {
        this.isLoading = false;
      }


    },
    async deletePrediction(id) {
      this.isLoading = true;

      try {
        const { data } = await this.$http.delete(`events/lotto-predictions/${id}`);

        // console.log(values);

        await this.$swal({
          icon: 'success',
          title: 'ทำรายการสำเร็จ',
          text: data.message,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })

        await this.getPredictions(1);

      } catch (e) {
        // console.log(e.response.data.message);
        await this.$swal({
          icon: 'error',
          title: 'ทำรายการไม่สำเร็จ',
          text: e.response.data.message,
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        })


      } finally {
        this.isLoading = false;
      }


    },
    async getCompetition(e) {
      this.isLoading = true;

      try {
        const { data } = await this.$http.get(`events/lotto-predictions/competitions?date=${new Date(e).toLocaleDateString()}`);

        this.competitions = data;
      } catch (e) {

      } finally {
        this.isLoading = false;
      }
    },
    async getPredictions(page) {
      this.isLoading = true;

      try {
        const { data } = await this.$http.get(`events/lotto-predictions?page=${page}`);

        this.items = data;
      } catch (e) {

      } finally {
        this.isLoading = false;
      }
    },
    async getLuckyWinners(page) {
      this.isLoading = true;

      try {
        const { data } = await this.$http.get(`events/lotto-predictions/lucky-winners?page=${page}`);

        this.luckyWinners = data;
      } catch (e) {

      } finally {
        this.isLoading = false;
      }
    },
    async fetchResults() {
      this.isLoading = true;

      try {
        const { data } = await this.$http.get('events/lotto-predictions/get_lotto_results');

        // await this.getPredictions(1);
        // console.log(data);
        // this.items = data;
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    async setStatus(id, status_id) {
      this.isLoading = true;

      try {
        const { data } = await this.$http.post(`events/lotto-predictions/set-status/${id}`, {
          status_id: status_id
        });

        await this.getPredictions(1);
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    }
  },
}
</script>